










import {
  defineComponent,
  ref,
  onMounted,
  useFetch
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@wemade-vsf/cache';
import { CmsPage } from '@wemade-vsf/magento-api';
import { useCmsContent } from '@wemade-vsf/composables'
import { useConfigStore, getMetaInfo } from '@wemade-vsf/core';
import CmsContent from 'components/theme/Content/CmsContent.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    CmsContent
  },
  middleware ({ redirect, route, localeRoute}) {
    if (route.query['createPassword']) {
      const { email, token } = route.query;
      return redirect(localeRoute({ name: 'reset-password', query: { email, token } }).fullPath);
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useCmsContent();
    const { storeConfig: { cms_home_page }} = useConfigStore()

    const page = ref<CmsPage | null>(null);
    const styles = ref<string | null>(null)

    useFetch(async () => {
      page.value = await loadPage({ identifier: cms_home_page ?? 'home' });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    // @ts-ignore
    return {
      page,
      styles
    };
  },
  head() {
    const meta = getMetaInfo(this.page);
    return {
      ...meta,
      bodyAttrs: {
        class: 'home'
      }
    }
  },
});
